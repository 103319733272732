import Vue from 'vue';
import './plugins/filters';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import { AppShell } from './shell';
// import './registerServiceWorker'

Vue.config.productionTip = false;

async function start() {
  await store.dispatch('auth/check');

  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(AppShell),
  }).$mount('#app');
}

start();
