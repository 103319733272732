<template>
  <v-snackbar
    v-model="snackbar.model"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    transition="slide-y-reverse-transition"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="clearToast"> Fermer </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppSnackbar',

  computed: {
    ...mapState({ snackbar: (state) => state.app.snackbar }),
  },

  methods: {
    ...mapActions({ clearToast: 'app/clearToast' }),
  },
};
</script>
