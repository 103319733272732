<template>
  <v-row dense>
    <v-col cols="12">
      <v-toolbar dense rounded elevation="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="primary"
              :loading="loading"
              :disabled="preventSaving"
              @click="save(item)"
            >
              <v-icon left>$save</v-icon>
              enregistrer
            </v-btn>
          </template>
          <span>enregistrer et afficher la liste des clients</span>
        </v-tooltip>

        <v-divider vertical class="mx-2"></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="info"
              :loading="loading"
              :disabled="preventSaving"
              @click="save(item, false)"
            >
              <v-icon left>$saveNew</v-icon>
              ajouter
            </v-btn>
          </template>
          <span>enregistrer et ajouter un nouveau client</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded color="warning" @click="cancel()">
              <v-icon left>$back</v-icon> annuler
            </v-btn>
          </template>
          <span>annuler et afficher la liste des clients</span>
        </v-tooltip>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-text>
          <client-form
            :item="item"
            :errors="errors"
            ref="clientForm"
          ></client-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { mapActions } from "vuex";
import { ClientForm } from "./components";
import { normalizeError } from "@/utils";

const defaultItem = {
  name: "",
  phone: "",
  email: "",
  address: "",
  rc: "",
  nif: "",
  nai: "",
};

export default {
  name: "CreateClient",
  components: { ClientForm },

  data: () => ({
    item: {},
    loading: false,
    canceled: false,
    errors: {},
  }),

  created() {
    this.reset();
  },

  computed: {
    prestine() {
      return isEqual(defaultItem, this.item);
    },
    preventSaving() {
      return (
        this.prestine ||
        this.loading ||
        !this.item.name ||
        !(!this.item.email || /.+@.+/.test(this.item.email))
      );
    },
  },

  methods: {
    ...mapActions({
      createClient: "clients/createClient",
      showToast: "app/showToast",
      showAlert: "app/showAppAlert",
      clearAlert: "app/clearAppAlert",
    }),

    reset() {
      this.item = Object.assign({}, defaultItem);
      this.errors = {};
      this.clearAlert();
    },

    cancel() {
      this.canceled = true;
      this.$router.push({ name: "clients" });
    },

    async save(item, finish = true) {
      this.loading = true;

      try {
        await this.createClient(item);

        this.showToast({ text: "Client enregistré avec succès" });
        this.reset();

        if (finish) {
          this.$router.push({ name: "clients" });
        } else {
          this.$refs.clientForm.setFocus();
        }
      } catch (error) {
        const { errors } = normalizeError(error);
        this.errors = errors;
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.prestine || this.canceled) return next();
    const answer = await this.$root.$confirm.leave();
    return answer ? next() : next(false);
  },
};
</script>
