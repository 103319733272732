import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import { authRoutes } from './modules/auth';
import { dashboardRoutes } from './modules/dashboard';
import { clientsRoutes } from './modules/clients';
import { articlesRoutes } from './modules/articles';
import { invoicesRoutes } from './modules/invoices';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...clientsRoutes,
  ...articlesRoutes,
  ...invoicesRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const guest = to.matched.some((r) => r.meta.guest === true);
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (guest && isAuthenticated) {
    next({ name: 'dashboard' });
  } else if (!guest && !isAuthenticated) {
    next({ name: 'login', query: { redirect: to.name } });
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta.title) store.dispatch('app/setPageTitle', to.meta.title);
  store.dispatch('app/clearAppAlert');
});

export default router;
