var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.invoice.details,"headers":_vm.headers,"items-per-page":50,"footer-props":{ itemsPerPageOptions: [50, 100, -1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('no-data')]},proxy:true},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.unit_price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"accent"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" $modify ")]),_c('v-icon',{attrs:{"small":"","color":"error lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" $trash ")])]}},(_vm.invoice.details && _vm.invoice.details.length)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{staticClass:"totals-row"},[_c('td',{attrs:{"colspan":headers.length}})]),_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"text-right font-weight-medium",attrs:{"colspan":headers.length - 2}},[_vm._v(" Total HT : ")]),_c('td',{staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totals.originalTotal))+" ")]),_c('td')]),(!!_vm.invoice.discount)?_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"text-right font-weight-medium",attrs:{"colspan":headers.length - 2}},[_vm._v(" Remise "+_vm._s(_vm.invoice.discount)+"% : ")]),_c('td',{staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totals.discountedAmount))+" ")]),_c('td')]):_vm._e(),(!!_vm.invoice.discount)?_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"text-right font-weight-medium",attrs:{"colspan":headers.length - 2}},[_vm._v(" Total avec remise HT : ")]),_c('td',{staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totals.totalWithoutTaxes))+" ")]),_c('td')]):_vm._e(),_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"text-right font-weight-medium",attrs:{"colspan":headers.length - 2}},[_vm._v(" TVA "+_vm._s(_vm.invoice.tax)+"% : ")]),_c('td',{staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totals.taxesAmount))+" ")]),_c('td')]),_c('tr',{staticClass:"totals-row"},[_c('td',{staticClass:"text-right font-weight-medium",attrs:{"colspan":headers.length - 2}},[_vm._v(" Total TTC : ")]),_c('td',{staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totals.grandTotal))+" ")]),_c('td')]),_c('tr',{staticClass:"totals-row"},[_c('td',{attrs:{"colspan":headers.length}})])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }