<template>
  <v-row dense>
    <v-col cols="12" md="4">
      <v-card>
        <v-card-text>
          <div class="mb-4">
            <v-subheader>Informations générales:</v-subheader>
            <v-divider></v-divider>
          </div>

          <v-text-field
            v-model.trim="item.name"
            :rules="[rules.required]"
            label="Nom & Prénom"
            hint="Votre nom complet."
            prepend-icon="$account"
            background-color="grey lighten-2"
            persistent-hint
            validate-on-blur
            filled
          ></v-text-field>

          <v-text-field
            v-model.trim="item.email"
            :rules="rules.email"
            label="Adresse E-mail"
            type="email"
            hint="Votre adresse électronique."
            prepend-icon="$at"
            background-color="grey lighten-2"
            persistent-hint
            validate-on-blur
            filled
          ></v-text-field>

          <div class="my-4">
            <v-subheader>Changer le mot de passe:</v-subheader>
            <v-divider></v-divider>
          </div>

          <v-text-field
            v-model="item.password"
            :rules="[rules.password]"
            prepend-icon="$password"
            label="Nouveau mot de passe"
            type="password"
            hint="Doit contenir au moins 6 caractères."
            background-color="grey lighten-2"
            persistent-hint
            validate-on-blur
            filled
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="primary"
            class="px-4"
            @click="save(item)"
            :disabled="prestine || !isValid"
          >
            <v-icon left>$save</v-icon> Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { difference } from "@/utils";
import { mapState, mapActions } from "vuex";

let defaultItem = { name: "", email: "", password: "" };

export default {
  name: "UserProfile",

  data: () => ({
    loading: false,
    item: { password: "" },
    rules: {
      required: value => !!value || "Ce champ est requis.",
      email: [
        value => !!value || "l'adresse e-mail ne peut être vide",
        value => /.+@.+/.test(value) || "l'adresse e-mail n'est pas valide"
      ],
      password: value => {
        if (!value) return true;
        return (
          value.length >= 6 ||
          "Votre mot de passe doit contenir au moins 6 caractères."
        );
      }
    }
  }),

  created() {
    this.reset();
  },

  computed: {
    ...mapState({ currentUser: state => state.auth.currentUser }),

    prestine() {
      return isEqual(defaultItem, this.item);
    },

    isValid() {
      const { name, email, password } = this.item;

      return (
        !!name &&
        !!email &&
        /.+@.+/.test(email) &&
        (password.length === 0 || password.length >= 6)
      );
    }
  },

  methods: {
    ...mapActions({
      showToast: "app/showToast",
      setTitle: "app/setPageTitle",
      showAlert: "app/showAppAlert",
      saveProfile: "auth/saveProfile"
    }),

    reset() {
      const { name, email } = this.currentUser;
      defaultItem = Object.assign({}, defaultItem, { name, email });
      this.item = Object.assign({}, defaultItem);
      this.setTitle(`Utilisateur : ${this.item.name}`);
    },

    async save(item) {
      this.loading = true;

      try {
        const { id } = this.currentUser;
        const data = difference(this.item, defaultItem);
        await this.saveProfile({ id, data });
        this.showToast({ text: "Profile enregistré avec succès" });
        this.reset();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
