<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="item.name"
          :rules="[rules.required]"
          :error-messages="errors.name"
          prepend-icon="$account"
          validate-on-blur
          ref="nameEdit"
          label="Client"
          hint="Nom du client"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.email"
          :rules="[rules.email]"
          :error-messages="errors.email"
          prepend-icon="$at"
          validate-on-blur
          label="Adresse E-mail"
          type="email"
          hint="Adresse E-mail du client"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.phone"
          :error-messages="errors.phone"
          prepend-icon="$phone"
          validate-on-blur
          label="Tél"
          hint="N° de Téléphone/Fax"
          filled
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="item.address"
          label="Adresse"
          hint="Adresse du client"
          prepend-icon="$pin"
          rows="3"
          persistent-hint
          validate-on-blur
          filled
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <hr class="my-2" />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.rc"
          :error-messages="errors.rc"
          validate-on-blur
          label="N° RC"
          hint="N° de registre de commerce"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.nai"
          :error-messages="errors.nai"
          validate-on-blur
          label="N° AI"
          hint="N° d'article d'imposition"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.nif"
          :error-messages="errors.nif"
          validate-on-blur
          label="N° IF"
          hint="Numéro d'Identification Fiscale"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClientForm",

  props: {
    item: { type: Object, required: true },
    errors: { type: Object, required: true },
  },

  data: () => ({
    rules: {
      required: (value) => !!value || "Ce champ est requis.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+/.test(value) || "l'adresse e-mail n'est pas valide";
      },
    },
  }),

  methods: {
    setFocus() {
      this.$refs.nameEdit.focus();
    },
  },
};
</script>
