<template>
  <v-row>
    <v-col cols="12" sm="6" lg="4">
      <stats-card
        color="info"
        icon="$invoices"
        title="Factures"
        :value="invoices.length"
        route="invoices"
        sub-icon="$clock"
        sub-text="Just Updated"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <stats-card
        color="teal"
        icon="$clients"
        title="Clients"
        :value="clients.length"
        route="clients"
        sub-icon="$clock"
        sub-text="Just Updated"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <stats-card
        color="success"
        icon="$progress"
        title="Articles"
        :value="articles.length"
        route="articles"
        sub-icon="$clock"
        sub-text="Just Updated"
      />
    </v-col>

    <!-- <v-col cols="12">
      <v-card shaped>
        <v-card-text>
          <invoices-chart :invoices="invoices"></invoices-chart>
        </v-card-text>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StatsCard from "./components/StatsCard";
// import InvoicesChart from "./components/InvoicesLineChart";

export default {
  name: "DashboardShell",
  components: { StatsCard /* InvoicesChart */ },

  data: () => ({
    loading: true,
  }),

  async created() {
    try {
      await Promise.all([
        this.getInvoices(),
        this.getClients(),
        this.getArticles(),
      ]);
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    ...mapState({
      invoices: (state) => state.invoices.items,
      clients: (state) => state.clients.items,
      articles: (state) => state.articles.items,
    }),
  },

  methods: {
    ...mapActions({
      getInvoices: "invoices/getAllInvoices",
      getClients: "clients/getAllClients",
      getArticles: "articles/getAllArticles",
      showAlert: "app/showAppAlert",
    }),
  },
};
</script>
