import Dashboard from "./Dashboard.shell.vue";

export default [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { title: "Tableau de bord" }
  }
];
