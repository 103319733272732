<template>
  <v-card>
    <v-card-title class="b-border pa-2">
      <v-text-field
        v-model="search"
        append-icon="$search"
        label="Chercher..."
        single-line
        hide-details
        clearable
        filled
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-btn
        text
        large
        rounded
        color="primary"
        class="px-4"
        :to="{ name: 'clients.create' }"
      >
        <v-icon left>$db</v-icon>Nouveau
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="50"
      :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
    >
      <template v-slot:no-data>
        <no-data></no-data>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="accent" class="mr-2" @click.stop="editItem(item)">
          $modify
        </v-icon>
        <v-icon
          small
          color="error"
          @click.stop="deleteItem(item)"
          :disabled="saving"
        >
          $trash
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NoData from "@/shared/NoData";
import { up } from "@/utils";

export default {
  name: "ClientsShell",
  components: { NoData },

  data: () => ({
    loading: true,
    saving: false,
    search: "",
    headers: [
      { text: up("Client"), value: "name" },
      { text: up("Tél"), value: "phone" },
      { text: up("E-mail"), value: "email" },
      {
        text: up("Actions"),
        value: "actions",
        sortable: false,
        align: "right",
      },
    ],
  }),

  async created() {
    try {
      await this.getClients();
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    ...mapState({ items: (state) => state.clients.items }),
  },

  methods: {
    ...mapActions({
      getClients: "clients/getAllClients",
      removeClient: "clients/removeClient",
      showAlert: "app/showAppAlert",
      clearAlert: "app/clearAppAlert",
      showToast: "app/showToast",
    }),

    editItem(item) {
      this.$router.push({ name: "clients.edit", params: { id: item.id } });
    },

    async deleteItem(item) {
      this.saving = true;

      try {
        await this.clearAlert();
        const answer = await this.$root.$confirm.destroy();
        if (!answer) return;
        await this.removeClient(item.id);
        this.showToast({ text: "Client supprimé avec succès!" });
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
