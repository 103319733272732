import ClientsPage from "./Clients.shell";
import CreateClient from "./Clients.create";
import EditClient from "./Clients.edit";

export default [
  {
    path: "/clients",
    name: "clients",
    component: ClientsPage,
    meta: { title: "Clients" }
  },
  {
    path: "/clients/nouveau",
    name: "clients.create",
    component: CreateClient,
    meta: { title: "Nouveau Client" }
  },
  {
    path: "/clients/:id/modifier",
    name: "clients.edit",
    component: EditClient
  }
];
