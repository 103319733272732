<template>
  <v-dialog
    v-model="options.model"
    :max-width="options.width"
    @keydown.esc="cancel"
    :style="{ zIndex: options.zIndex }"
    persistent
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">
          {{ options.title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text v-show="!!options.message" class="pt-5">
        {{ options.message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click.native="agree">Oui</v-btn>
        <v-btn color="primary" text @click.native="cancel">Non</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  const defaultOptions = {
    model: false,
    message: '',
    title: 'Attention!',
    color: 'warning',
    width: 320,
    zIndex: 200,
  };

  export default {
    name: 'AppConfirm',

    data: () => ({
      resolve: null,
      reject: null,
      options: {},
    }),

    methods: {
      open(options) {
        this.options = Object.assign({}, defaultOptions, options, {
          model: true,
        });
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      reset() {
        this.options = Object.assign({}, defaultOptions);
      },

      agree() {
        this.resolve(true);
        this.reset();
      },

      cancel() {
        this.resolve(false);
        this.reset();
      },

      // pre-defined helpers
      destroy() {
        return this.open({
          color: 'error',
          message:
            'Es-tu absolument sûr? une fois supprimé, vous ne pouvez pas récupérer cet enregistrement. Voulez-vous continuer?',
        });
      },

      leave() {
        return this.open({
          message:
            'Vous avez des données non sauvegardées, êtes-vous sûr de vouloir quitter sans les sauvegarder?',
        });
      },

      invalidate() {
        return this.open({
          message:
            'marquer la facture comme proforma? vous devez définir un nouveau numéro de facture.',
        });
      },
    },
  };
</script>
