import ArticlesShell from "./Articles.shell.vue";
import CreateArticle from "./Articles.create.vue";
import EditArticle from "./Articles.edit.vue";

export default [
  {
    path: "/articles",
    name: "articles",
    component: ArticlesShell,
    meta: { title: "Articles" },
  },
  {
    path: "/articles/nouveau",
    name: "articles.create",
    component: CreateArticle,
    meta: { title: "Nouveau Article" },
  },
  {
    path: "/articles/:id/modifier",
    name: "articles.edit",
    component: EditArticle,
  },
];
