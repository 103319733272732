<template>
  <v-alert
    v-model="alert.model"
    @input="alertClosed"
    :type="alert.type"
    border="left"
    dismissible
    transition="scale-transition"
  >
    {{ alert.message }}
  </v-alert>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'AppAlert',

    computed: {
      ...mapState({ alert: state => state.app.alert }),
    },

    watch: {
      'alert.model': function(val) {
        if (val) {
          window.scrollTo(0, 0);
        }
      },
    },

    methods: {
      alertClosed(e) {
        // clear the error from the store
        // console.log("Alert Input triggered!", e);
      },
    },
  };
</script>
