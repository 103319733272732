<template>
  <v-navigation-drawer app dark permanent :mini-variant="mini" color="primary">
    <v-list class="py-1">
      <v-list-item>
        <v-list-item-icon>
          <v-icon>$home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="title text-uppercase">
            {{ appName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="{ name: item.route }"
        :exact="!!item.exact"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider></v-divider> -->
      <!-- <v-subheader>Facturation</v-subheader> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { icons } from "../shared";

export default {
  name: "AppDrawer",

  data: () => ({
    items: [
      {
        title: "Dashboard",
        icon: icons.dashboard,
        route: "dashboard",
        exact: true
      },
      { title: "Articles", icon: icons.progress, route: "articles" },
      { title: "Clients", icon: icons.clients, route: "clients" },
      { title: "Factures", icon: icons.invoices, route: "invoices" }
    ]
  }),

  computed: {
    ...mapState({
      appName: state => state.app.name,
      mini: state => state.app.drawer.mini
    })
  }
};
</script>
