<template>
  <v-row dense justify="center">
    <v-col md="10" lg="8">
      <v-row dense>
        <v-col cols="12">
          <v-toolbar dense rounded elevation="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  rounded
                  color="primary"
                  @click="save(item)"
                  :disabled="preventSaving"
                  :loading="saving"
                >
                  <v-icon left>$save</v-icon>
                  enregistrer
                </v-btn>
              </template>
              <span>enregistrer et afficher la liste des articles</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text rounded color="warning" @click="cancel()">
                  <v-icon left>$back</v-icon> annuler
                </v-btn>
              </template>
              <span>annuler et afficher la liste des articles</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>

        <v-col cols="12">
          <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
          <v-card :disabled="loading">
            <v-card-text>
              <article-form :item="item" :errors="errors"></article-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { mapActions } from "vuex";
import { normalizeError, normalizeEntity, difference } from "@/utils";
import { ArticleForm } from "./components";

export default {
  name: "EditArticle",
  components: { ArticleForm },

  data: () => ({
    item: {},
    original: null,
    saving: false,
    loading: true,
    canceled: false,
    errors: {},
  }),

  async created() {
    try {
      const article = await this.getArticle(this.$route.params.id);
      this.original = normalizeEntity(article);
      this.reset();
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    prestine() {
      return isEqual(this.original, this.item);
    },
    preventSaving() {
      return this.prestine || this.saving || !this.item.name || !this.item.unit;
    },
  },

  methods: {
    ...mapActions({
      getArticle: "articles/getArticle",
      updateArticle: "articles/updateArticle",
      setPageTitle: "app/setPageTitle",
      showAlert: "app/showAppAlert",
      showToast: "app/showToast",
    }),

    reset() {
      this.item = Object.assign({}, this.original);
      this.errors = {};
      this.setPageTitle(`Articles / ${this.item.name}`);
    },

    cancel() {
      this.canceled = true;
      this.$router.push({ name: "articles" });
    },

    async save(item) {
      this.saving = true;

      try {
        const data = difference(item, this.original);
        await this.updateArticle({ id: item.id, data });
        this.showToast({ text: "Article enregistré avec succès" });
        this.reset();
        this.$router.push({ name: "articles" });
      } catch (error) {
        const { errors } = normalizeError(error);
        this.errors = errors;
        this.showAlert(error);
      } finally {
        this.saving = false;
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.prestine || this.canceled) return next();
    const answer = await this.$root.$confirm.leave();
    return answer ? next() : next(false);
  },
};
</script>
