<template>
  <v-row dense>
    <v-col cols="12">
      <v-toolbar dense rounded elevation="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="primary"
              :loading="loading"
              :disabled="!isValid || loading"
              @click="save(item)"
            >
              <v-icon left>$save</v-icon>
              enregistrer
            </v-btn>
          </template>
          <span>enregistrer et afficher la liste des facture</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="warning"
              :disabled="loading"
              @click="cancel"
            >
              <v-icon left>$back</v-icon> annuler
            </v-btn>
          </template>
          <span>annuler et afficher la liste des facture</span>
        </v-tooltip>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-card height="100%">
        <v-tabs v-model="tabs">
          <v-tab href="#invoice-header">En-tête de facture</v-tab>
          <v-tab href="#invoice-options">Options</v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="invoice-header">
              <invoice-form :item="item" :errors="errors"></invoice-form>
            </v-tab-item>
            <v-tab-item value="invoice-options">
              <invoice-options :item="item" :errors="errors"></invoice-options>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <NewInvoiceDetailDialog
        v-if="showNewDetailDialog"
        :open="showNewDetailDialog"
        @save="saveDetail"
        @cancel="showNewDetailDialog = false"
      />

      <EditInvoiceDetailDialog
        v-if="showEditDetailDialog"
        :data="currentDetail"
        :open="showEditDetailDialog"
        @save="updateDetail"
        @cancel="resetDetail"
      />

      <v-card height="100%">
        <v-card-title class="subtitle-1 justify-space-between py-3">
          <span class="grey--text text-uppercase">Détails des articles</span>
          <v-btn text rounded color="primary" @click="showNewDetailDialog = true">
            <v-icon left>$db</v-icon>
            Ajouter
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <InvoiceDetailsTable
          :invoice="item"
          @edit-item="editDetail"
          @remove-item="removeDetail"
        ></InvoiceDetailsTable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import isEqual from 'lodash/isEqual';
  import { mapActions } from 'vuex';
  import { normalizeError } from '@/utils';
  import { formatIsoDate } from '@/plugins/filters';
  import { getInvoicePayload, normalizeInvoiceDetail } from './invoices.helpers';
  import invoiceService from './invoices.service';
  import {
    InvoiceForm,
    InvoiceOptions,
    InvoiceDetailsTable,
    NewInvoiceDetailDialog,
    EditInvoiceDetailDialog,
  } from './components';

  const defaultItem = {
    client_id: '',
    invoice_date: formatIsoDate(new Date()),
    invoice_number: '',
    tax: 19,
    discount: '',
    max_rows: '',
    payment_method: '',
    advance: 50,
    delivery: 21,
    details: [],
  };

  export default {
    name: 'CreateInvoice',
    components: {
      InvoiceForm,
      InvoiceOptions,
      InvoiceDetailsTable,
      NewInvoiceDetailDialog,
      EditInvoiceDetailDialog,
    },

    data: () => ({
      item: {},
      currentDetail: null,
      errors: {},
      loading: false,
      canceled: false,
      showNewDetailDialog: false,
      showEditDetailDialog: false,
      tabs: null,
    }),

    async created() {
      const nbr = await invoiceService.getNextNumber(false);
      defaultItem.invoice_number = nbr;
      this.reset();
    },

    computed: {
      prestine() {
        return isEqual(defaultItem, this.item);
      },

      isValid() {
        return (
          !!this.item.client_id &&
          !!this.item.invoice_date &&
          !!this.item.invoice_number &&
          !!this.item.tax &&
          !!this.item.advance &&
          !!this.item.delivery &&
          this.item.details.length > 0
        );
      },
    },

    methods: {
      ...mapActions({
        showToast: 'app/showToast',
        showAlert: 'app/showAppAlert',
        clearAlert: 'app/clearAppAlert',
        createInvoice: 'invoices/createInvoice',
      }),

      reset() {
        this.item = Object.assign({}, defaultItem, { details: [] });
        this.errors = {};
        this.clearAlert();
      },

      saveDetail(detail) {
        this.item.details.push(detail);
      },

      editDetail(detail) {
        this.currentDetail = normalizeInvoiceDetail(detail);
        this.showEditDetailDialog = true;
      },

      updateDetail(detail) {
        const i = this.item.details.findIndex((p) => p.__id__ === detail.__id__);
        if (i > -1) this.item.details.splice(i, 1, detail);
        this.resetDetail();
      },

      removeDetail(detail) {
        const i = this.item.details.findIndex((p) => p.__id__ === detail.__id__);
        if (i > -1) this.item.details.splice(i, 1);
      },

      resetDetail() {
        this.showEditDetailDialog = false;
        this.currentDetail = null;
      },

      cancel() {
        this.canceled = true;
        this.$router.push({ name: 'invoices' });
      },

      async save(item) {
        this.loading = true;

        try {
          const payload = getInvoicePayload(item);
          const invoice = await this.createInvoice(payload);

          this.showToast({ text: 'Facture enregistrée avec succès' });
          this.reset();

          this.$router.push({
            name: 'invoices.show',
            params: { id: invoice.id },
          });
        } catch (error) {
          const { errors } = normalizeError(error);
          this.errors = errors;
          this.showAlert(error);
        } finally {
          this.loading = false;
        }
      },
    },

    async beforeRouteLeave(to, from, next) {
      if (this.prestine || this.canceled) return next();
      const answer = await this.$root.$confirm.leave();
      return answer ? next() : next(false);
    },
  };
</script>
