import axios from '@/axios';

const endpoint = 'invoices';

export default {
  async getAll() {
    const res = await axios.get(endpoint);
    return res.data.data;
  },

  async getById(id) {
    const res = await axios.get(`${endpoint}/${id}`);
    return res.data.data;
  },

  async create(item) {
    const res = await axios.post(endpoint, item);
    return res.data.data;
  },

  async update(id, data) {
    const res = await axios.put(`${endpoint}/${id}`, data);
    return res.data.data;
  },

  async remove(id) {
    const res = await axios.delete(`${endpoint}/${id}`);
    return res.data;
  },

  async getNextNumber(approved = true) {
    const res = await axios.post(`${endpoint}/numbers`, { approved });
    return res.data.data;
  },

  async createDetail(item) {
    const res = await axios.post('invoice-details', item);
    return res.data.data;
  },

  async updateDetail(id, data) {
    const res = await axios.put(`invoice-details/${id}`, data);
    return res.data.data;
  },

  async removeDetail(id) {
    const res = await axios.delete(`invoice-details/${id}`);
    return res.data;
  },
};
