import clients from "./clients.service";

const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";

const store = { namespaced: true };

store.state = { items: [] };

store.mutations = {
  [GET_ALL_CLIENTS]: (state, items) => {
    state.items = items;
  },

  [CREATE_CLIENT_SUCCESS]: (state, item) => {
    state.items.push(item);
  },

  [UPDATE_CLIENT_SUCCESS]: (state, item) => {
    const index = state.items.findIndex(i => i.id === item.id);
    state.items.splice(index, 1, item);
  },

  [REMOVE_CLIENT_SUCCESS]: (state, id) => {
    state.items = state.items.filter(item => item.id !== id);
  }
};

store.actions = {
  async getAllClients({ commit }) {
    const items = await clients.getAll();
    commit(GET_ALL_CLIENTS, items);
  },

  async getClient(_, id) {
    return await clients.getById(id);
  },

  async createClient({ commit }, payload) {
    const client = await clients.create(payload);
    commit(CREATE_CLIENT_SUCCESS, client);
    return client;
  },

  async updateClient({ commit }, { id, data }) {
    const client = await clients.update(id, data);
    commit(UPDATE_CLIENT_SUCCESS, client);
  },

  async removeClient({ commit }, id) {
    await clients.remove(id);
    commit(REMOVE_CLIENT_SUCCESS, id);
  }
};

export default store;
