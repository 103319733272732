<template>
  <v-data-table
    :items="invoice.details"
    :headers="headers"
    :items-per-page="50"
    :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
    hide-default-footer
  >
    <template v-slot:no-data>
      <no-data></no-data>
    </template>

    <template v-slot:[`item.unit_price`]="{ item }">
      {{ item.unit_price | currency }}
    </template>

    <template v-slot:[`item.total`]="{ item }">
      {{ item.total | currency }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small color="accent" class="mr-2" @click.stop="editItem(item)">
        $modify
      </v-icon>

      <v-icon small color="error lighten-1" @click.stop="remove(item)">
        $trash
      </v-icon>
    </template>

    <template
      v-if="invoice.details && invoice.details.length"
      v-slot:[`body.append`]="{ headers }"
    >
      <tr class="totals-row">
        <td :colspan="headers.length"></td>
      </tr>

      <tr class="totals-row">
        <td class="text-right font-weight-medium" :colspan="headers.length - 2">
          Total HT :
        </td>
        <td class="text-right font-weight-medium">
          {{ totals.originalTotal | currency }}
        </td>
        <td></td>
      </tr>

      <tr v-if="!!invoice.discount" class="totals-row">
        <td class="text-right font-weight-medium" :colspan="headers.length - 2">
          Remise {{ invoice.discount }}% :
        </td>
        <td class="text-right font-weight-medium">
          {{ totals.discountedAmount | currency }}
        </td>
        <td></td>
      </tr>

      <tr v-if="!!invoice.discount" class="totals-row">
        <td class="text-right font-weight-medium" :colspan="headers.length - 2">
          Total avec remise HT :
        </td>
        <td class="text-right font-weight-medium">
          {{ totals.totalWithoutTaxes | currency }}
        </td>
        <td></td>
      </tr>

      <tr class="totals-row">
        <td class="text-right font-weight-medium" :colspan="headers.length - 2">
          TVA {{ invoice.tax }}% :
        </td>
        <td class="text-right font-weight-medium">
          {{ totals.taxesAmount | currency }}
        </td>
        <td></td>
      </tr>

      <tr class="totals-row">
        <td class="text-right font-weight-medium" :colspan="headers.length - 2">
          Total TTC :
        </td>
        <td class="text-right font-weight-medium">
          {{ totals.grandTotal | currency }}
        </td>
        <td></td>
      </tr>

      <tr class="totals-row">
        <td :colspan="headers.length"></td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import NoData from '@/shared/NoData';
import { getTotals } from '@/modules/invoices/invoices.helpers';
import { up } from '@/utils';

export default {
  name: 'InvoiceDetailsTable',
  components: { NoData },

  props: {
    invoice: { type: Object, required: true },
  },

  data: () => ({
    headers: [
      { text: up('Désignation'), value: 'article.name' },
      { text: up('Unité'), value: 'unit', align: 'center' },
      { text: up('Quantité'), value: 'quantity', align: 'center' },
      { text: up('Prix U'), value: 'unit_price', align: 'right' },
      { text: up('Montant'), value: 'total', align: 'right' },
      {
        text: up('Actions'),
        value: 'actions',
        sortable: false,
        align: 'right',
      },
    ],
  }),

  computed: {
    totals() {
      const { tax, discount } = this.invoice;
      const total = this.invoice.details.reduce((acc, cur) => {
        return acc + cur.total;
      }, 0);

      return getTotals({ total, tax, discount });
    },
  },

  methods: {
    editItem(item) {
      this.$emit('edit-item', item);
    },

    async remove(item) {
      const answer = await this.$root.$confirm.destroy();
      if (answer) this.$emit('remove-item', item);
    },
  },
};
</script>

<style lang="scss">
tr:hover {
  background: none !important;
}

.totals-row {
  td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none !important;
  }
}
</style>
