<template>
  <v-row dense>
    <invoice-confirm-dialog
      :open="confirmDialog"
      :item="item"
      @cancel="confirmDialog = false"
      @save="confirm"
    >
    </invoice-confirm-dialog>

    <v-col cols="12">
      <v-toolbar dense rounded elevation="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="primary"
              :loading="saving"
              :disabled="!isValid || prestine || saving"
              @click="save(item)"
            >
              <v-icon left>$save</v-icon>
              enregistrer
            </v-btn>
          </template>
          <span>enregistrer et afficher la liste des facture</span>
        </v-tooltip>

        <v-divider vertical class="mx-1"></v-divider>

        <template v-if="item.is_approved">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text rounded @click="confirmInvoice(false)">
                <v-icon left>$undo</v-icon>
                invalider
              </v-btn>
            </template>
            <span>marquer la facture comme proforma</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                text
                rounded
                color="success"
                @click="confirmInvoice(true)"
              >
                <v-icon left>$check</v-icon>
                confirmer
              </v-btn>
            </template>
            <span>confirmer la facture</span>
          </v-tooltip>
        </template>

        <v-divider vertical class="mx-1"></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded color="error" @click="deleteItem(item)">
              <v-icon left>$trash</v-icon>
              supprimer
            </v-btn>
          </template>
          <span>supprimer la facture</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="warning"
              :disabled="saving"
              @click="cancel"
            >
              <v-icon left>$back</v-icon> annuler
            </v-btn>
          </template>
          <span>annuler et afficher la liste des facture</span>
        </v-tooltip>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      <v-card :disabled="loading">
        <v-tabs v-model="tabs">
          <v-tab href="#invoice-header">En-tête de facture</v-tab>
          <v-tab href="#invoice-options">Options</v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="invoice-header">
              <invoice-form :item="item" :errors="errors"></invoice-form>
            </v-tab-item>
            <v-tab-item value="invoice-options">
              <invoice-options :item="item" :errors="errors"></invoice-options>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <NewInvoiceDetailDialog
        v-if="showNewDetailDialog"
        :open="showNewDetailDialog"
        @save="addDetail"
        @cancel="showNewDetailDialog = false"
      />

      <EditInvoiceDetailDialog
        v-if="showEditDetailDialog"
        :data="currentDetail"
        :open="showEditDetailDialog"
        @save="updateDetail"
        @cancel="cancelEditDetail"
      />

      <v-card :disabled="loading">
        <v-card-title class="subtitle-1 justify-space-between py-3">
          <span class="grey--text text-uppercase">Détails des articles</span>
          <v-btn text rounded color="primary" @click="showNewDetailDialog = true">
            <v-icon left>$db</v-icon>
            Ajouter
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <InvoiceDetailsTable
          :invoice="item"
          @edit-item="editDetail"
          @remove-item="removeDetail"
        ></InvoiceDetailsTable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions } from 'vuex';
  import cloneDeep from 'lodash/cloneDeep';
  import { normalizeError } from '@/utils';
  import {
    getInvoiceDetailDiff,
    getInvoiceDiff,
    isInvoicePrestine,
    normalizeInvoiceDetail,
    validNumber,
  } from './invoices.helpers';
  import invoiceService from './invoices.service';
  import {
    InvoiceForm,
    InvoiceOptions,
    InvoiceDetailsTable,
    InvoiceConfirmDialog,
    NewInvoiceDetailDialog,
    EditInvoiceDetailDialog,
  } from './components';

  let originalItem = null;

  export default {
    name: 'EditInvoice',
    components: {
      InvoiceForm,
      InvoiceOptions,
      InvoiceDetailsTable,
      InvoiceConfirmDialog,
      NewInvoiceDetailDialog,
      EditInvoiceDetailDialog,
    },

    data: () => ({
      item: { details: [] },
      currentDetail: null,
      errors: {},
      canceled: false,
      saving: false,
      loading: true,
      showNewDetailDialog: false,
      showEditDetailDialog: false,
      confirmDialog: false,
      tabs: null,
    }),

    async created() {
      try {
        originalItem = await this.getInvoice(this.$route.params.id);
        this.reset();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },

    computed: {
      prestine() {
        return isInvoicePrestine(this.item, originalItem);
      },

      isValid() {
        return (
          !!this.item.client_id &&
          !!this.item.invoice_date &&
          validNumber(this.item) &&
          !!this.item.tax &&
          !!this.item.advance &&
          !!this.item.delivery &&
          this.item.details.length > 0
        );
      },
    },

    methods: {
      ...mapActions('invoices', [
        'getInvoice',
        'updateInvoice',
        'removeInvoice',
        'createInvoiceDetail',
        'updateInvoiceDetail',
        'removeInvoiceDetail',
      ]),
      ...mapActions({
        setPageTitle: 'app/setPageTitle',
        showAlert: 'app/showAppAlert',
        clearAlert: 'app/clearAppAlert',
        showToast: 'app/showToast',
      }),

      reset() {
        this.item = cloneDeep(originalItem);
        this.setPageTitle(`FACTURE N°: ${this.item.invoice_number}`);
        this.errors = {};
        this.clearAlert();
      },

      cancel() {
        this.canceled = true;
        this.$router.push({ name: 'invoices' });
      },

      async addDetail(detail) {
        this.saving = true;

        try {
          const { article_id, unit, quantity, unit_price, observation } = detail;
          const payload = {
            invoice_id: this.item.id,
            article_id,
            unit,
            quantity,
            unit_price,
            observation,
          };
          const added = await this.createInvoiceDetail(payload);
          this.item.details.push(added);
          this.showToast({ text: 'Article enregistré avec succès.' });
        } catch (error) {
          const { errors } = normalizeError(error);
          this.errors = errors;
          this.showAlert(error);
        } finally {
          this.saving = false;
        }
      },

      editDetail(detail) {
        this.currentDetail = normalizeInvoiceDetail(detail);
        this.showEditDetailDialog = true;
      },

      async updateDetail(detail) {
        this.saving = true;

        try {
          const diff = getInvoiceDetailDiff(detail, this.currentDetail);

          const updated = await this.updateInvoiceDetail({
            id: detail.id,
            data: diff,
          });

          const i = this.item.details.findIndex((d) => d.id === detail.id);
          if (i > -1) this.item.details.splice(i, 1, updated);

          this.showToast({ text: 'Article enregistré avec succès.' });
          this.resetDetail();
        } catch (error) {
          const { errors } = normalizeError(error);
          this.errors = errors;
          this.showAlert(error);
        } finally {
          this.saving = false;
        }
      },

      resetDetail() {
        this.showEditDetailDialog = false;
        this.currentDetail = null;
      },

      cancelEditDetail() {
        this.resetDetail();
      },

      async removeDetail(detail) {
        this.saving = true;

        try {
          await this.removeInvoiceDetail(detail);
          const i = this.item.details.findIndex((d) => d.id === detail.id);
          if (i > -1) this.item.details.splice(i, 1);
          this.showToast({ text: 'Article supprimé avec succès.' });
        } catch (error) {
          const { errors } = normalizeError(error);
          this.errors = errors;
          this.showAlert(error);
        } finally {
          this.saving = false;
        }
      },

      confirm({ invoice_number, approved_at }) {
        this.item.approved_at = approved_at;
        this.item.invoice_number = invoice_number;
        this.confirmDialog = false;
      },

      async confirmInvoice(confirm) {
        if (confirm === true) {
          this.confirmDialog = true;
        } else if (await this.$root.$confirm.invalidate()) {
          this.item.approved_at = '';
          this.item.invoice_number = await invoiceService.getNextNumber(false);
          this.item.is_approved = false;
        }
      },

      async deleteItem(item) {
        this.saving = true;

        try {
          await this.clearAlert();
          const answer = await this.$root.$confirm.destroy();
          if (!answer) return;
          await this.removeInvoice(item.id);
          this.$router.push({ name: 'invoices' });
          this.showToast({ text: 'Facture supprimée avec succès!' });
        } catch (error) {
          this.showAlert(error);
        } finally {
          this.saving = false;
        }
      },

      async save(item) {
        this.saving = true;

        try {
          const payload = getInvoiceDiff(item, originalItem);

          const invoice = await this.updateInvoice({
            id: item.id,
            data: payload,
          });

          this.showToast({ text: 'Facture enregistrée avec succès' });
          this.reset();

          this.$router.push({
            name: 'invoices.show',
            params: { id: invoice.id },
          });
        } catch (error) {
          const { errors } = normalizeError(error);
          this.errors = errors;
          this.showAlert(error);
        } finally {
          this.saving = false;
        }
      },
    },

    async beforeRouteLeave(to, from, next) {
      if (this.prestine || this.canceled) return next();
      const answer = await this.$root.$confirm.leave();
      return answer ? next() : next(false);
    },
  };
</script>
