import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/lib/locale/fr';
import { icons } from '../shared';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'mdiSvg',
    values: icons,
  },
});
