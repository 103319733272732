import {
  mdiProgressWrench,
  mdiShieldAccountOutline,
  mdiEyeOutline,
  mdiAt,
  mdiAccountGroupOutline,
  mdiAccountBoxMultiple,
  mdiAccountOutline,
  mdiLockOutline,
  mdiContentSave,
  mdiDatabaseSearch,
  mdiViewDashboard,
  mdiHomeOutline,
  mdiMagnify,
  mdiDatabasePlus,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiClipboardTextMultipleOutline,
  mdiPercentOutline,
  mdiFilePdfBox,
  mdiCheck,
  mdiPhone,
  mdiCog,
  mdiPin,
  mdiMap,
  mdiMapMarker,
  mdiArrowBottomLeft,
  mdiArrowLeft,
  mdiContentSaveMove,
  mdiUndo,
  mdiClock,
  mdiClockOutline,
} from "@mdi/js";

export default {
  home: mdiHomeOutline,
  dashboard: mdiViewDashboard,
  clients: mdiAccountGroupOutline,
  account: mdiAccountOutline,
  search: mdiMagnify,
  db: mdiDatabasePlus,
  trash: mdiDeleteOutline,
  modify: mdiSquareEditOutline,
  save: mdiContentSave,
  saveNew: mdiContentSaveMove,
  invoices: mdiClipboardTextMultipleOutline,
  percent: mdiPercentOutline,
  shield: mdiShieldAccountOutline,
  password: mdiLockOutline,
  noData: mdiDatabaseSearch,
  progress: mdiProgressWrench,
  pdf: mdiFilePdfBox,
  check: mdiCheck,
  at: mdiAt,
  phone: mdiPhone,
  settings: mdiCog,
  pin: mdiMapMarker,
  back: mdiArrowLeft,
  undo: mdiUndo,
  clock: mdiClockOutline,

  show: mdiEyeOutline,
  users: mdiAccountBoxMultiple,
};
