<template>
  <v-row dense>
    <v-col cols="12">
      <v-toolbar dense elevation="1">
        <v-btn text color="info" @click="preview(item)">
          <v-icon left>$pdf</v-icon>
          {{ item.is_approved ? 'facture' : 'aperçu' }}
        </v-btn>

        <template v-if="item.is_approved">
          <v-divider vertical class="mx-2"></v-divider>

          <v-btn text color="info" @click="preview(item, 'RECEIPT')">
            <v-icon left>$pdf</v-icon> Bon de livraison
          </v-btn>
        </template>

        <template v-else>
          <v-divider vertical class="mx-1"></v-divider>

          <v-btn text color="info" @click="preview(item, 'REQUEST')">
            <v-icon left>$pdf</v-icon> Demande de fabrication
          </v-btn>
        </template>

        <v-divider vertical class="mx-1"></v-divider>

        <v-spacer></v-spacer>

        <v-btn text color="warning" @click="editItem(item)">
          <v-icon left>$edit</v-icon> modifier
        </v-btn>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-row no-gutters class="h-100">
        <v-col>
          <v-list elevation="1" height="100%">
            <v-list-item>
              <span class="invoice-label mr-2">N° de facture:</span>
              <span class="font-weight-medium">{{ item.invoice_number }}</span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <span class="invoice-label mr-2">Date de facture:</span>
              <span class="font-weight-medium">
                {{ item.invoice_date | date }}
              </span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <span class="invoice-label mr-2">Client:</span>
              <span class="font-weight-medium">
                {{ item.client && item.client.name }}
              </span>
            </v-list-item>

            <template v-if="item.payment_method">
              <v-divider></v-divider>

              <v-list-item>
                <span class="invoice-label mr-2">Mode de paiement:</span>
                <span class="font-weight-medium d-inline-block text-truncate">
                  {{ item.payment_method }}
                </span>
              </v-list-item>
            </template>
          </v-list>
        </v-col>

        <v-col>
          <v-list elevation="1" height="100%">
            <v-list-item>
              <span class="invoice-label mr-2">Etat de facture:</span>
              <span class="text-uppercase font-weight-medium">
                {{ item.state }}
              </span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <span class="invoice-label mr-2">TVA (%):</span>
              <span class="font-weight-medium">{{ item.tax }}%</span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <span class="invoice-label mr-2">Remise (%):</span>
              <span class="font-weight-medium text-uppercase">
                {{ item.discount ? `${item.discount}%` : 'Aucune' }}
              </span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-text class="pa-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Désignation</th>
                  <th class="text-center">Unité</th>
                  <th class="text-center">Qté</th>
                  <th class="text-right">Prix U</th>
                  <th class="text-right">Montant</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in item.details" :key="item.id">
                  <td>{{ item.article.name }}</td>
                  <td class="text-center">{{ item.unit }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="text-right">{{ item.unit_price }}</td>
                  <td class="text-right">{{ item.total }}</td>
                </tr>
              </tbody>

              <tfoot class="font-weight-medium">
                <tr>
                  <td colspan="5" class="px-0" style="height: auto">
                    <v-divider class="mb-2"></v-divider>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">Total HT :</td>
                  <td>{{ item.total | currency }}</td>
                </tr>

                <template v-if="!!item.discount">
                  <tr>
                    <td colspan="4">Remise {{ item.discount }}% :</td>
                    <td>
                      {{ totals.discountedAmount | currency }}
                    </td>
                  </tr>

                  <tr>
                    <td colspan="4">Total avec remise HT :</td>
                    <td>
                      {{ totals.totalWithoutTaxes | currency }}
                    </td>
                  </tr>
                </template>

                <tr>
                  <td colspan="4">TVA {{ item.tax }}% :</td>
                  <td>
                    {{ totals.taxesAmount | currency }}
                  </td>
                </tr>

                <tr>
                  <td colspan="4">Total TTC :</td>
                  <td>{{ totals.grandTotal | currency }}</td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { getTotals } from './invoices.helpers';

export default {
  name: 'ShowInvoice',

  data: () => ({
    item: {},
    loading: true,
  }),

  async created() {
    try {
      this.item = await this.getInvoice(this.$route.params.id);
      this.setPageTitle(`FACTURE N°: ${this.item.invoice_number}`);
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    totals() {
      return getTotals(this.item);
    },
  },

  methods: {
    ...mapActions({
      getInvoice: 'invoices/getInvoice',
      setPageTitle: 'app/setPageTitle',
      showAlert: 'app/showAppAlert',
      clearAlert: 'app/clearAppAlert',
      showToast: 'app/showToast',
    }),

    editItem(item) {
      this.$router.push({ name: 'invoices.edit', params: { id: item.id } });
    },

    preview(item, docType = 'INVOICE') {
      const params = { id: item.id };
      const query = { docType };
      this.$router.push({ name: 'invoices.preview', params, query });
    },
  },
};
</script>

<style lang="scss" scoped>
tfoot td {
  text-align: right;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
}

.invoice-label {
  font-style: italic;
  text-decoration: underline;
  color: #263238;
  min-width: 150px;
}
</style>
