<template>
  <v-row dense>
    <client-dialog
      :open="clientDialog"
      @cancel="clientDialog = false"
      @save="setClient"
    ></client-dialog>

    <v-col cols="12" sm="6" md="4">
      <v-autocomplete
        v-model.number="item.client_id"
        :items="clients"
        item-text="name"
        item-value="id"
        :error-messages="errors.client_id"
        filled
        label="Client"
        hint="Selectionner un client"
        persistent-hint
      >
        <template v-slot:append-outer>
          <v-icon @click="clientDialog = true">$db</v-icon>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-dialog
        ref="picker"
        v-model="invoiceDatePicker"
        :return-value.sync="item.invoice_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="formatedInvoiceDate"
            :rules="[rules.required]"
            :error-messages="errors.invoice_date"
            label="Date"
            hint="Date de facture"
            persistent-hint
            readonly
            filled
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.invoice_date" scrollable locale="fr">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="invoiceDatePicker = false">
            Annuler
          </v-btn>
          <v-btn text color="primary" @click="$refs.picker.save(item.invoice_date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model.trim="item.invoice_number"
        :rules="[rules.required, rules.nbre]"
        :error-messages="errors.invoice_number"
        label="N° de facture"
        hint="N° temporaire du facture proforma"
        persistent-hint
        filled
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model.number="item.tax"
        :rules="[rules.required]"
        :error-messages="errors.tax"
        label="TVA"
        hint="Taxe sur la valeur ajoutée"
        suffix="%"
        persistent-hint
        type="number"
        min="0"
        max="100"
        filled
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model.number="item.discount"
        :error-messages="errors.discount"
        label="Remise"
        hint="Pourcentage de remise"
        suffix="%"
        persistent-hint
        type="number"
        min="0"
        max="100"
        filled
      ></v-text-field>
    </v-col>

    <v-col v-if="!!item.approved_at" cols="12" sm="6" md="4">
      <v-dialog
        ref="approvedDateDialog"
        v-model="approvedDatePicker"
        :return-value.sync="item.approved_at"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="formatedApprovedDate"
            label="Date de confirmation"
            hint="Date de confirmation"
            persistent-hint
            readonly
            filled
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.approved_at" scrollable locale="fr">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="approvedDatePicker = false">
            Annuler
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.approvedDateDialog.save(item.approved_at)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { formatDate } from '@/plugins/filters';
  import { ClientDialog } from '@/modules/clients';
  import { invoiceNumberRegex } from '../invoices.helpers';

  export default {
    name: 'InvoiceForm',
    components: { ClientDialog },
    props: {
      item: { type: Object, required: true },
      errors: { type: Object },
    },

    data: () => ({
      clientDialog: false,
      invoiceDatePicker: false,
      approvedDatePicker: false,
      rules: {
        required: (value) => !!value || 'Ce champ est requis.',
        nbre: (value) => {
          if (!value || !!value.match(invoiceNumberRegex)) return true;
          return 'Format du n° de facture invalide.';
        },
      },
    }),

    async created() {
      try {
        await this.getClients();
      } catch (error) {
        this.showAlert(error);
      }
    },

    computed: {
      ...mapState({ clients: (state) => state.clients.items }),

      formatedInvoiceDate() {
        return formatDate(this.item.invoice_date);
      },

      formatedApprovedDate() {
        return formatDate(this.item.approved_at);
      },
    },

    methods: {
      ...mapActions({
        showAlert: 'app/showAppAlert',
        getClients: 'clients/getAllClients',
      }),

      setClient(client) {
        this.item.client_id = client.id;
        this.clientDialog = false;
      },
    },
  };
</script>
