<template>
  <v-dialog v-model="open" persistent max-width="520px">
    <v-card>
      <v-card-title class="subtitle-1 text-uppercase font-weight-light">
        Nouveau élément
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pt-4">
        <invoice-detail-form ref="detailForm" :item="item"></invoice-detail-form>

        <v-switch v-model="addNew" label="Ajouter un autre"></v-switch>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text rounded @click="cancel">Annuler</v-btn>
        <v-btn :disabled="!isValid" color="primary" text rounded @click="save(item)">
          ajouter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import InvoiceDetailForm from './InvoiceDetailForm';

const defaultItem = {
  article_id: '',
  unit: 'U',
  quantity: '',
  unit_price: '',
  observation: '',
};

export default {
  name: 'NewInvoiceDetailDialog',
  components: { InvoiceDetailForm },

  props: {
    open: { type: Boolean, default: false },
  },

  data: () => ({
    item: {},
    addNew: false,
  }),

  created() {
    this.reset();
  },

  computed: {
    isValid() {
      return (
        !!this.item.article_id &&
        !!this.item.unit &&
        !!this.item.quantity &&
        parseFloat(this.item.quantity) > 0 &&
        parseFloat(this.item.unit_price) >= 0
      );
    },
  },

  methods: {
    reset() {
      this.item = Object.assign({}, defaultItem);
    },

    cancel() {
      this.reset();
      this.$refs.detailForm.clear();
      this.$emit('cancel');
    },

    save(item) {
      item.__id__ = uniqueId();
      item.total = item.quantity * item.unit_price;

      this.$emit('save', item);
      this.reset();
      this.$refs.detailForm.clear();

      if (!this.addNew) {
        this.$emit('cancel');
      }
    },
  },
};
</script>
