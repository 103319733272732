export const normalizeEntity = (entity) => {
  let _entity = {};

  Object.keys(entity).forEach((key) => {
    _entity[key] =
      entity[key] === null || entity[key] === undefined ? '' : entity[key];
  });

  return _entity;
};

export const normalizeError = (error) => {
  if (error.response && error.response.status === 422) {
    return {
      message: "Les données fournies n'étaient pas valides.",
      errors: error.response.data.errors,
    };
  }

  if (error.response) {
    const message =
      (error.response.data && error.response.data.message) || error.statusText;

    return { message };
  }

  if (error.message != 'Network Error') {
    return { message: error.message };
  }

  return { message: "Oops! Quelque chose d'horrible est arrivé." };
};
