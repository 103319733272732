<template>
  <v-dialog v-model="open" persistent max-width="400px">
    <v-card>
      <v-card-title class="subtitle-1 text-uppercase font-weight-light">
        Confirmation de facture
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pt-4">
        <v-dialog
          ref="approvedDateDialog"
          v-model="approvedDatePicker"
          :return-value.sync="item.approved_at"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="formatedApprovedDate"
              label="Date de confirmation"
              background-color="grey lighten-2"
              hint="Date de confirmation"
              persistent-hint
              readonly
              filled
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="item.approved_at" scrollable locale="fr">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="approvedDatePicker = false">
              Annuler
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.approvedDateDialog.save(item.approved_at)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-text-field
          v-model.trim="item.invoice_number"
          label="N° de facture"
          background-color="grey lighten-2"
          hint="N° de facture"
          persistent-hint
          filled
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text rounded @click="$emit('cancel')">Annuler</v-btn>
        <v-btn
          color="primary"
          text
          rounded
          @click="$emit('save', item)"
          :disabled="loading || !isValid"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import { normalizeError } from '@/utils';
  import { formatDate, formatIsoDate } from '@/plugins/filters';
  import invoiceService from '../invoices.service';
  import { approvedInvoiceNumberRegex, invoiceNumberRegex } from '../invoices.helpers';

  const defaultItem = {
    invoice_number: '',
    approved_at: formatIsoDate(new Date()),
  };

  export default {
    name: 'InvoiceConfirmDialog',

    props: {
      open: { type: Boolean, default: false },
    },

    data: () => ({
      item: {},
      approvedDatePicker: false,
      loading: false,
    }),

    async created() {
      this.loading = true;

      try {
        defaultItem.invoice_number = await invoiceService.getNextNumber();
        this.reset();
      } catch (error) {
        const { message } = normalizeError(error);
        this.showToast({ text: message, color: 'error' });
      } finally {
        this.loading = false;
      }
    },

    computed: {
      formatedApprovedDate() {
        return formatDate(this.item.approved_at);
      },

      isValid() {
        return (
          !!this.item.approved_at &&
          !!this.item.invoice_number &&
          this.item.invoice_number.match(approvedInvoiceNumberRegex)
        );
      },
    },

    methods: {
      ...mapActions({ showToast: 'app/showToast' }),

      reset() {
        this.item = Object.assign({}, defaultItem);
      },
    },

    watch: {
      open(val) {
        if (val) {
          this.reset();
        }
      },
    },
  };
</script>
