<template>
  <v-row dense justify="center">
    <v-col md="10" lg="8">
      <v-row dense>
        <v-col cols="12">
          <v-toolbar dense elevation="1" rounded>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  rounded
                  color="primary"
                  :loading="loading"
                  :disabled="preventSaving"
                  @click="save(item)"
                >
                  <v-icon left>$save</v-icon>
                  enregistrer
                </v-btn>
              </template>
              <span>enregistrer et afficher la liste des articles</span>
            </v-tooltip>

            <v-divider vertical class="mx-2"></v-divider>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  rounded
                  color="info"
                  :loading="loading"
                  :disabled="preventSaving"
                  @click="save(item, false)"
                >
                  <v-icon left>$saveNew</v-icon>
                  ajouter
                </v-btn>
              </template>
              <span>enregistrer et ajouter un nouvel article</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text rounded color="warning" @click="cancel()">
                  <v-icon left>$back</v-icon> annuler
                </v-btn>
              </template>
              <span>annuler et afficher la liste des articles</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-text>
              <article-form
                :item="item"
                :errors="errors"
                ref="articleForm"
              ></article-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { mapActions } from "vuex";
import { normalizeError } from "@/utils";
import { ArticleForm } from "./components";

const defaultItem = {
  name: "",
  unit: "U",
  is_free: false,
  unit_price: "",
  description: "",
};

export default {
  name: "CreateArticle",
  components: { ArticleForm },

  data: () => ({
    item: {},
    loading: false,
    canceled: false,
    errors: {},
  }),

  created() {
    this.reset();
  },

  computed: {
    prestine() {
      return isEqual(defaultItem, this.item);
    },
    preventSaving() {
      return (
        this.prestine || this.loading || !this.item.name || !this.item.unit
      );
    },
  },

  methods: {
    ...mapActions({
      createArticle: "articles/createArticle",
      showToast: "app/showToast",
      showAlert: "app/showAppAlert",
      clearAlert: "app/clearAppAlert",
    }),

    reset() {
      this.item = Object.assign({}, defaultItem);
      this.errors = {};
      this.clearAlert();
    },

    cancel() {
      this.canceled = true;
      this.$router.push({ name: "articles" });
    },

    async save(item, finish = true) {
      this.loading = true;

      try {
        await this.createArticle(item);

        this.showToast({ text: "Article enregistré avec succès" });
        this.reset();

        if (finish) {
          this.$router.push({ name: "articles" });
        } else {
          this.$refs.articleForm.setFocus();
        }
      } catch (error) {
        const { errors } = normalizeError(error);
        this.errors = errors;
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.prestine || this.canceled) return next();
    const answer = await this.$root.$confirm.leave();
    return answer ? next() : next(false);
  },
};
</script>
