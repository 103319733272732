import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { difference, exceptObject, normalizeEntity } from '@/utils';
import { formatIsoDate, formatIsoDateTime } from '@/plugins/filters';

const normalizeDateTime = (date) => {
  const now = formatIsoDateTime(new Date());
  return now.replace(formatIsoDate(new Date()), date);
};

export const getInvoicePayload = (item) => ({
  ...item,
  invoice_date: normalizeDateTime(item.invoice_date),
  details: item.details.map((i) => ({
    article_id: i.article_id,
    unit: i.unit,
    quantity: i.quantity,
    unit_price: i.unit_price,
  })),
});

export const normalizeInvoice = (item) => ({
  ...normalizeEntity(item),
  state: item.is_approved ? 'confirmée' : 'proforma',
  details: item.details.map(normalizeInvoiceDetail),
});

export const normalizeInvoiceDetail = (item) => {
  const entity = normalizeEntity(item);
  return entity.total === undefined
    ? { ...entity, total: item.quantity * item.unit_price }
    : entity;
};

export const getInvoiceDiff = (current, original) => {
  return difference(exceptObject(current), exceptObject(original));
};

export const isInvoicePrestine = (current, original) => {
  const paths = [
    'client_id',
    'invoice_date',
    'invoice_number',
    'approved_at',
    'tax',
    'discount',
    'notes',
    'max_rows',
    'payment_method',
    'advance',
    'delivery',
  ];

  return isEqual(pick(current, paths), pick(original, paths));
};

export const getInvoiceDetailDiff = (current, original) => {
  const { total, ...detail } = difference(
    exceptObject(current),
    exceptObject(original)
  );

  return detail;
};

export const getTotals = (item) => {
  const { total, tax, discount } = item;

  const discountedAmount = !!discount ? (total * discount) / 100 : 0;
  const totalWithoutTaxes = total - discountedAmount;
  const taxesAmount = (totalWithoutTaxes * tax) / 100;
  const grandTotal = totalWithoutTaxes + taxesAmount;

  return {
    originalTotal: total,
    discountedAmount,
    totalWithoutTaxes,
    taxesAmount,
    grandTotal,
  };
};

export const invoiceNumberRegex = /^[1-9]\d{0,3}\/((NG|FR)\/LAB|PR)\/20\d{2}$/i;
export const approvedInvoiceNumberRegex = /^[1-9]\d{0,3}\/(NG|FR)\/LAB\/20\d{2}$/i;

export const validNumber = (item) => {
  if (!item.invoice_number) return false;

  if (!item.is_approved) {
    return !!item.invoice_number.match(invoiceNumberRegex);
  } else {
    return !!item.invoice_number.match(approvedInvoiceNumberRegex);
  }
};
