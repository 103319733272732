import { normalizeError } from "@/utils";

const SET_PAGE_TITLE = "SET_PAGE_TITLE";
const TOGGLE_APP_DRAWER = "TOGGLE_APP_DRAWER";
const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SHOW_APP_ALERT = "SHOW_APP_ALERT";
export const CLEAR_APP_ALERT = "CLEAR_APP_ALERT";

const store = { namespaced: true };

store.state = {
  name: process.env.VUE_APP_NAME,
  pageTitle: "",
  drawer: {
    mini: false,
  },
  snackbar: {
    model: false,
    color: "",
    timeout: -1,
    text: "",
  },
  alert: {
    model: false,
    type: "error",
    message: "",
    errors: null,
  },
};

store.getters = {
  hasError: (state) => !!state.alert.model,
  // isDrawerOpen: state => !state.drawer.mini
};

store.mutations = {
  [SET_PAGE_TITLE]: (state, title) => {
    state.pageTitle = title;
  },

  [TOGGLE_APP_DRAWER]: (state, open) => {
    if (typeof open === "boolean") {
      state.drawer.mini = open ? false : true;
    } else {
      state.drawer.mini = !state.drawer.mini;
    }
  },

  [SHOW_NOTIFICATION]: (state, payload) => {
    const { text, color = "success", timeout = 4000 } = payload;
    Object.assign(state.snackbar, { model: !!text, text, color, timeout });
  },

  [CLEAR_NOTIFICATION]: (state) => {
    Object.assign(state.snackbar, { model: false, text: "", color: "", timeout: 0 });
  },

  [SHOW_APP_ALERT]: (state, payload) => {
    const { message, errors } = payload;
    Object.assign(state.alert, { model: true, message, errors });
  },

  [CLEAR_APP_ALERT]: (state) => {
    Object.assign(state.alert, {
      model: false,
      type: "error",
      message: "",
      errors: null,
    });
  },
};

store.actions = {
  setPageTitle({ commit }, title) {
    commit(SET_PAGE_TITLE, title);
  },

  toggleDrawer({ commit }, open) {
    commit(TOGGLE_APP_DRAWER, open);
  },

  showToast({ commit }, payload) {
    commit(SHOW_NOTIFICATION, payload);
  },

  clearToast({ commit }) {
    commit(CLEAR_NOTIFICATION);
  },

  showAppAlert({ commit }, error) {
    commit(SHOW_APP_ALERT, normalizeError(error));
  },

  clearAppAlert({ commit }) {
    commit(CLEAR_APP_ALERT);
  },
};

export default store;
