import axios from "@/axios";

const endpoint = "clients";

export default {
  async getAll() {
    const res = await axios.get(endpoint);
    return res.data.data;
  },

  async getById(id) {
    const res = await axios.get(`${endpoint}/${id}`);
    return res.data.data;
  },

  async create(item) {
    const res = await axios.post(endpoint, item);
    return res.data.data;
  },

  async update(id, data) {
    const res = await axios.put(`${endpoint}/${id}`, data);
    return res.data.data;
  },

  async remove(id) {
    const res = await axios.delete(`${endpoint}/${id}`);
    return res.data.data;
  }
};
