<template>
  <v-form ref="form">
    <article-dialog
      :open="articleDialog"
      @cancel="articleDialog = false"
      @save="setArticle"
    ></article-dialog>

    <v-row dense>
      <v-col cols="12">
        <v-autocomplete
          v-model.number="item.article_id"
          :rules="[rules.required]"
          :items="articles"
          item-text="name"
          item-value="id"
          ref="articleEdit"
          @change="articleChanged"
          label="Article"
          hint="Selectionner un article"
          persistent-hint
          validate-on-blur
          filled
          autofocus
        >
          <template v-slot:append-outer>
            <v-icon @click="articleDialog = true">$db</v-icon>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="item.unit"
          :rules="[rules.required]"
          label="Unité"
          hint="Unité de mesure"
          persistent-hint
          validate-on-blur
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model.number="item.quantity"
          :rules="[rules.required]"
          ref="quantityEdit"
          type="number"
          min="0"
          label="Quantité"
          hint="Quantité commandée"
          persistent-hint
          validate-on-blur
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model.number="item.unit_price"
          :rules="[rules.numeric]"
          type="number"
          min="0"
          label="Prix Unitaire"
          hint="Prix Unitaire"
          persistent-hint
          validate-on-blur
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="item.observation"
          label="Observation"
          hint="Observation (pour BL)"
          rows="3"
          persistent-hint
          validate-on-blur
          filled
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ArticleDialog } from "@/modules/articles";

export default {
  name: "InvoiceDetailForm",
  components: { ArticleDialog },

  props: {
    item: { type: Object, required: true },
  },

  data: () => ({
    loading: false,
    articleDialog: false,
    rules: {
      required: (value) => !!value || "Ce champ est requis.",
      numeric: (value) => parseFloat(value) >= 0 || "Chiffre non-valide.",
    },
  }),

  async created() {
    this.loading = true;

    try {
      await this.getArticles();
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    ...mapState({ articles: (state) => state.articles.items }),
  },

  methods: {
    ...mapActions({
      showAlert: "app/showAppAlert",
      getArticles: "articles/getAllArticles",
    }),

    setArticle(article) {
      this.item.article_id = article.id;
      this.item.article = { name: article.name };
      if (article.unit) this.item.unit = article.unit;

      if (article.is_free) {
        this.item.unit_price = 0;
      } else {
        this.item.unit_price = article.unit_price || 0;
      }

      this.$refs.articleEdit.focus();
      this.articleDialog = false;
    },

    articleChanged(id) {
      if (!id) return;
      const article = this.articles.find((a) => a.id === id);
      this.item.article = { name: article.name };
      if (article.unit) this.item.unit = article.unit;

      if (article.is_free) {
        this.item.unit_price = 0;
      } else {
        this.item.unit_price = article.unit_price || 0;
      }

      this.$refs.quantityEdit.focus();
    },

    clear() {
      this.$refs.articleEdit.internalSearch = undefined;
      this.$refs.form.resetValidation();
    },
  },
};
</script>
