<template>
  <iframe
    :src="src"
    frameborder="0"
    height="100%"
    width="100%"
    scrolling="auto"
  ></iframe>
</template>

<script>
export default {
  name: "InvoicePreview",

  data: () => ({ src: "" }),

  created() {
    const baseUrl = process.env.VUE_APP_API_BASE_PATH;
    const { id } = this.$route.params;
    const { docType } = this.$route.query;

    this.src = `${baseUrl}/invoices/${id}/pdf?type=${docType}`;
  },
};
</script>
