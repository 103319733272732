import Vue from 'vue';
import { format, parseISO } from 'date-fns';
// import fr from "date-fns/locale/fr";

// Helpers
const tryParse = (value) => (value instanceof Date ? value : parseISO(value));
const formatter = (style = 'decimal') => {
  return new Intl.NumberFormat('fr-FR', {
    style,
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
};

// export const formatMonth = value =>
//   value ? format(tryParse(value), "MMMM yyyy", { locale: fr }) : "";
// export const formatIsoMonth = value => format(tryParse(value), "yyyy-MM");

export const formatDate = (value) =>
  value ? format(tryParse(value), 'dd/MM/yyyy') : '';
export const formatIsoDate = (value) => {
  return value ? format(tryParse(value), 'yyyy-MM-dd') : '';
};
export const formatIsoDateTime = (value) => {
  return value ? format(tryParse(value), 'yyyy-MM-dd HH:mm:ss') : '';
};

export const formatNumber = (value) => {
  if (!value) return '';
  return formatter().format(value);
};

// const formatAsDays = value => (value ? `${value} jours` : "");

export const formatPercentage = (value) => {
  if (!value) return '';
  return formatter('percent').format(value);
};

export const truncate = (value, count) => {
  if (!value) return '';
  if (value.length <= count) return value;
  return `${String(value).substr(0, count)}...`;
};

Vue.filter('date', formatDate);
Vue.filter('percent', formatPercentage);
Vue.filter('currency', formatNumber);
Vue.filter('truncate', truncate);
