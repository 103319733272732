<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title class="subtitle-1 text-uppercase font-weight-light">
        Nouveau Client
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="px-2">
        <client-form
          :item="client"
          :errors="errors"
          :in-dialog="true"
        ></client-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel')">Annuler</v-btn>
        <v-btn
          :disabled="!client.name"
          color="blue darken-1"
          text
          @click="save(client)"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ClientForm from "./ClientForm";
import { normalizeError } from "@/utils";

export default {
  name: "ClientDialog",
  components: { ClientForm },

  props: {
    open: { type: Boolean, default: false },
  },

  data: () => ({
    client: {},
    errors: {},
    loading: true,
  }),

  methods: {
    ...mapActions({
      showToast: "app/showToast",
      createClient: "clients/createClient",
    }),

    reset() {
      this.client = {};
      this.errors = {};
    },

    async save(item) {
      this.loading = true;

      try {
        const newClient = await this.createClient(item);
        this.reset();
        this.$emit("save", newClient);
      } catch (error) {
        const { message, errors } = normalizeError(error);
        this.errors = errors;
        this.showToast({ text: message, color: "error" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
