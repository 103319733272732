<template>
  <v-row justify="center" align="center" class="h-100">
    <v-col cols="12" sm="8" md="4">
      <v-card>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title
            class="d-flex align-center blue--text text-uppercase"
          >
            <v-icon color="blue" class="mr-1">$shield</v-icon> s'identifier
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <login-form></login-form>
      </v-card>

      <div class="text-center font-weight-thin mt-2">
        Fryditech - Mobilier moderne
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { LoginForm } from "./components";

export default {
  name: "LoginPage",
  components: { LoginForm }
};
</script>
