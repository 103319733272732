<template>
  <v-app-bar app hide-on-scroll scroll-threshold="250" elevation="2">
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title class="subtitle-1 text-uppercase" style="width: 100%;">
      <span
        class="d-block text-truncate"
        v-text="pageTitle"
        style="max-width: 80%;"
      ></span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu transition="slide-x-reverse-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>$account</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'users.show' }">
          <v-list-item-title class="font-weight-medium">
            Options
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="signOut">
          <v-list-item-title class="font-weight-medium">
            Déconnecter
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    computed: {
      ...mapState({ pageTitle: state => state.app.pageTitle }),
    },
    methods: {
      ...mapActions({
        toggleDrawer: 'app/toggleDrawer',
        logout: 'auth/logout',
      }),

      async signOut() {
        await this.logout();
        this.$router.push({ name: 'login' });
      },
    },
  };
</script>
