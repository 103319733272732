import Vue from 'vue';
import Vuex from 'vuex';
import { appStore } from './shell';
import { authStore } from './modules/auth';
import { articlesStore } from './modules/articles';
import { clientsStore } from './modules/clients';
import { invoicesStore } from './modules/invoices';
import { dashboardStore } from './modules/dashboard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: appStore,
    auth: authStore,
    articles: articlesStore,
    clients: clientsStore,
    invoices: invoicesStore,
    dashboard: dashboardStore,
  },
});
