<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <v-text-field
        v-model.number="item.advance"
        :rules="[rules.required]"
        :error-messages="errors.advance"
        label="Avance"
        hint="Echéance de paiement"
        suffix="%"
        persistent-hint
        type="number"
        min="0"
        max="100"
        filled
      ></v-text-field>

      <v-text-field
        v-model.number="item.delivery"
        :rules="[rules.required]"
        :error-messages="errors.delivery"
        label="Livraison (jours)"
        hint="Délais de livraison"
        persistent-hint
        type="number"
        min="0"
        filled
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="6">
      <v-text-field
        v-model.number="item.max_rows"
        :error-messages="errors.max_rows"
        label="Lignes par page"
        hint="Nombre de lignes par page"
        persistent-hint
        type="number"
        min="0"
        max="100"
        filled
      ></v-text-field>

      <v-textarea
        v-model="item.notes"
        label="Remarque"
        hint="Remarque (pour BL & DF)"
        rows="4"
        persistent-hint
        validate-on-blur
        filled
      ></v-textarea>

      <!-- <v-combobox
        v-model="item.payment_method"
        :items="paymentMethods"
        :error-messages="errors.payment_method"
        label="Mode de paiement"
        hint="Mode de paiement"
        persistent-hint
        clearable
        filled
      ></v-combobox> -->
    </v-col>

    <!-- <v-col cols="12" md="4">

    </v-col> -->
  </v-row>
</template>

<script>
  export default {
    name: 'InvoiceOptions',

    props: {
      item: { type: Object, required: true },
      errors: { type: Object },
    },

    data: () => ({
      paymentMethods: ['Espèce', 'Chèque certifié', 'Virement bancaire'],
      rules: {
        required: (value) => !!value || 'Ce champ est requis.',
      },
    }),
  };
</script>
