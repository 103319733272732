<template>
  <v-app>
    <app-snackbar></app-snackbar>
    <app-confirm ref="confirm"></app-confirm>

    <v-slide-y-transition>
      <app-drawer v-if="isAuthenticated"></app-drawer>
    </v-slide-y-transition>

    <v-slide-x-transition>
      <app-topbar v-if="isAuthenticated"></app-topbar>
    </v-slide-x-transition>

    <v-main class="grey lighten-2">
      <v-container fluid class="h-100">
        <app-alert></app-alert>

        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AppDrawer from './App.drawer';
  import AppTopbar from './App.topbar';
  import AppAlert from './App.alert';
  import AppSnackbar from './App.snackbar';
  import AppConfirm from './App.confirm';

  export default {
    name: 'AppShell',
    components: { AppDrawer, AppTopbar, AppAlert, AppSnackbar, AppConfirm },

    mounted() {
      this.$root.$confirm = this.$refs.confirm;
    },

    computed: {
      ...mapGetters({ isAuthenticated: 'auth/isAuthenticated' }),
    },
  };
</script>

<style lang="scss">
  .v-btn {
    letter-spacing: normal;
  }
  .v-card__title.b-border {
    border-bottom: #1976d2 solid 2px;
  }
  .h-100 {
    height: 100% !important;
  }
</style>
