<template>
  <v-dialog v-model="open" persistent max-width="520px">
    <v-card>
      <v-card-title class="subtitle-1 text-uppercase font-weight-light">
        Modification d'un élément
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <InvoiceDetailForm ref="detailForm" :item="item" />
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn text @click="$emit('cancel')">Annuler</v-btn>
        <v-btn
          :disabled="!isValid || prestine"
          color="primary"
          text
          @click="save(item)"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import InvoiceDetailForm from './InvoiceDetailForm';

export default {
  name: 'EditInvoiceDetailDialog',
  components: { InvoiceDetailForm },

  props: {
    data: { type: Object, required: true },
    open: { type: Boolean, default: false },
  },

  data() {
    return {
      original: this.data,
      item: cloneDeep(this.data),
    };
  },

  computed: {
    isValid() {
      return (
        !!this.item.article_id &&
        !!this.item.unit &&
        parseFloat(this.item.quantity) > 0 &&
        parseFloat(this.item.unit_price) >= 0
      );
    },

    prestine() {
      return isEqual(this.item, this.original);
    },
  },

  methods: {
    save(item) {
      item.total = item.quantity * item.unit_price;
      this.$emit('save', item);
    },
  },
};
</script>
