import articles from "./articles.service";

const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
const REMOVE_ARTICLE_SUCCESS = "REMOVE_ARTICLE_SUCCESS";

const store = { namespaced: true };

store.state = { items: [] };

store.mutations = {
  [GET_ALL_ARTICLES]: (state, items) => {
    state.items = items;
  },

  [CREATE_ARTICLE_SUCCESS]: (state, item) => {
    state.items.push(item);
  },

  [UPDATE_ARTICLE_SUCCESS]: (state, item) => {
    const index = state.items.findIndex(i => i.id === item.id);
    state.items.splice(index, 1, item);
  },

  [REMOVE_ARTICLE_SUCCESS]: (state, id) => {
    state.items = state.items.filter(item => item.id !== id);
  }
};

store.actions = {
  async getAllArticles({ commit }) {
    const items = await articles.getAll();
    commit(GET_ALL_ARTICLES, items);
  },

  async getArticle(_, id) {
    return await articles.getById(id);
  },

  async createArticle({ commit }, payload) {
    const article = await articles.create(payload);
    commit(CREATE_ARTICLE_SUCCESS, article);
    return article;
  },

  async updateArticle({ commit }, { id, data }) {
    const article = await articles.update(id, data);
    commit(UPDATE_ARTICLE_SUCCESS, article);
  },

  async removeArticle({ commit }, id) {
    await articles.remove(id);
    commit(REMOVE_ARTICLE_SUCCESS, id);
  }
};

export default store;
