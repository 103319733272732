<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-textarea
          v-model="item.name"
          :rules="[rules.required]"
          :error-messages="errors.name"
          validate-on-blur
          ref="nameEdit"
          label="Désignation"
          hint="Désignation de l'article"
          rows="4"
          persistent-hint
          filled
          autofocus
        ></v-textarea>
      </v-col>

      <v-col cols="12" :md="inDialog ? 12 : 6">
        <v-switch
          v-model="item.is_free"
          inset
          flat
          label="Mesure d'accompagnement"
        >
        </v-switch>
      </v-col>

      <v-col cols="12"></v-col>

      <v-col cols="12" :md="item.is_free ? 12 : 6">
        <v-text-field
          v-model="item.unit"
          :rules="[rules.required]"
          :error-messages="errors.unit"
          validate-on-blur
          label="Unité"
          hint="Unité de mesure"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-scale-transition hide-on-leave>
        <v-col cols="12" md="6" v-if="!item.is_free">
          <v-text-field
            v-model.number="item.unit_price"
            :error-messages="errors.unit_price"
            type="number"
            min="0"
            label="Prix Unitaire"
            hint="Le prix d'article"
            persistent-hint
            filled
          ></v-text-field> </v-col
      ></v-scale-transition>

      <v-col cols="12" v-if="!inDialog">
        <v-textarea
          v-model="item.description"
          label="Description"
          hint="Notes ou description d'article"
          rows="4"
          persistent-hint
          filled
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ArticleForm",

  props: {
    item: { type: Object, required: true },
    errors: { type: Object, required: true },
    inDialog: { type: Boolean, default: false },
  },

  data: () => ({
    rules: {
      required: (value) => !!value || "Ce champ est requis.",
    },
  }),

  methods: {
    setFocus() {
      this.$refs.nameEdit.focus();
    },
  },
};
</script>
