import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} subject Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(subject, base) {
  return transform(subject, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}

export const exceptObject = (subject) => {
  return pickBy(subject, (value) => !isObject(value));
};

export function arrayDiff(base, subject) {
  // Find values that are in base but not in subject
  const baseDiff = base.filter(function (baseItem) {
    return !subject.some(function (obj2) {
      return baseItem.id ? baseItem.id === obj2.id : baseItem.__id__ === obj2.__id__;
    });
  });

  // Find values that are in subject but not in base
  const subjectDiff = subject.filter(function (obj) {
    return !base.some(function (obj2) {
      return obj.id ? obj.id === obj2.id : obj.__id__ === obj2.__id__;
    });
  });

  // Combine the two arrays of unique entries
  return baseDiff.concat(subjectDiff);
}
