var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"b-border pa-2"},[_c('v-text-field',{attrs:{"append-icon":"$search","label":"Chercher...","single-line":"","hide-details":"","clearable":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"text":"","large":"","rounded":"","color":"primary","to":{ name: 'invoices.create' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$db")]),_vm._v("Nouveau ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":50,"footer-props":{ itemsPerPageOptions: [50, 100, -1] }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('no-data')]},proxy:true},{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'invoices.show', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.invoice_number)+" ")])]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.invoice_date))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","pill":"","small":"","color":item.is_approved ? 'info' : 'orange'}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"accent"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" $modify ")]),_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" $trash ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }