<template>
  <v-form ref="form" @submit.prevent="login">
    <v-card-text>
      <v-text-field
        v-model="email"
        :rules="rules.email"
        :error-messages="errors.email"
        prepend-icon="$account"
        name="login"
        label="Nom d'utilisateur"
        type="email"
        required
        filled
      ></v-text-field>

      <v-text-field
        v-model="password"
        :rules="rules.password"
        :error-messages="errors.password"
        prepend-icon="$password"
        name="password"
        label="Mot de passe"
        type="password"
        required
        filled
      ></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn
        type="submit"
        color="primary"
        class="px-6 font-weight-medium"
        :loading="loading"
        :disabled="loading"
        rounded
        outlined
      >
        Connecter
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { normalizeError } from "@/utils";

export default {
  name: "LoginForm",

  data: () => ({
    email: "",
    password: "",
    remember: true,
    loading: false,
    rules: {
      email: [
        v => !!v || "l'adresse e-mail ne peut être vide",
        v => /.+@.+/.test(v) || "l'adresse e-mail n'est pas valide"
      ],
      password: [
        v => !!v || "Mot de passe ne peut être vide",
        v =>
          v.length >= 6 || "Le mot de passe doit contenir au moins 6 caractères"
      ]
    },
    errors: {}
  }),

  computed: {
    ...mapState({ currentUser: state => state.auth.currentUser })
  },

  methods: {
    ...mapActions({
      authenticate: "auth/login",
      showToast: "app/showToast"
    }),

    async login() {
      if (!this.$refs.form.validate()) return;
      const { email, password, remember } = this;
      this.loading = true;

      try {
        await this.authenticate({ email, password, remember });
        this.showToast({
          text: `Bienvenue ${this.currentUser.name}!`,
          color: "info"
        });

        const redirectTo = this.$route.query.redirect || "dashboard";
        this.$router.push({ name: redirectTo });
      } catch (error) {
        const { message, errors } = normalizeError(error);
        this.errors = errors;
        this.showToast({ text: message, color: "error" });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
