import axios from "@/axios";

const BACKEND_URL = process.env.VUE_APP_BACKEND_PATH;
const LOGIN_ENDPOINT = `${BACKEND_URL}/login`;
const LOGOUT_ENDPOINT = `${BACKEND_URL}/logout`;
const SANCTUM_ENDPOINT = `${BACKEND_URL}/sanctum/csrf-cookie`;

export default {
  async login(credentials) {
    const response = await axios.post(LOGIN_ENDPOINT, credentials);
    return response.data;
  },

  async logout() {
    return await axios.post(LOGOUT_ENDPOINT);
  },

  async check() {
    await axios.get(SANCTUM_ENDPOINT);
    const res = await axios.post("users/me");
    return res.data;
  },

  async saveProfile(id, data) {
    const res = await axios.put(`users/${id}`, data);
    return res.data.data;
  }
};
