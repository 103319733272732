<template>
  <v-card class="pa-3 mt-7" shaped>
    <div class="d-flex grow flex-wrap">
      <v-sheet
        :color="color"
        max-height="90"
        width="auto"
        elevation="6"
        class="text-start v-card--material__heading mb-n6 pa-7"
        dark
      >
        <v-icon size="32" v-text="icon" />
      </v-sheet>

      <div class="ml-auto text-right">
        <div class="body-3 grey--text" v-text="title" />

        <h3 class="display-2 font-weight-light text--primary">
          {{ value }}
        </h3>
      </div>
    </div>

    <v-col cols="12" class="px-0">
      <v-divider />
    </v-col>

    <div class="d-flex justify-space-between align-center">
      <v-btn text small :to="{ name: route }" :color="color">
        <v-icon left>$show</v-icon> afficher
      </v-btn>

      <v-btn icon :to="{ name: `${route}.create` }" :color="color">
        <v-icon>$plus</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StatsCard",

  props: {
    color: { type: String, default: "success" },
    icon: { type: String, required: true },
    subIcon: { type: String, default: undefined },
    subText: { type: String, default: undefined },
    title: { type: String, default: undefined },
    value: { type: Number, default: undefined },
    route: { type: String, required: true },
  },
};
</script>

<style lang="scss">
.v-card--material__heading {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
</style>
