<template>
  <v-dialog v-model="open" persistent max-width="500px">
    <v-card>
      <v-card-title class="subtitle-1 text-uppercase font-weight-light">
        Nouveau Article
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="px-2">
        <article-form
          :item="article"
          :errors="errors"
          :in-dialog="true"
        ></article-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Annuler</v-btn>
        <v-btn
          :loading="loading"
          :disabled="preventSaving"
          color="blue darken-1"
          text
          @click="save(article)"
        >
          enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import isEqual from "lodash/isEqual";
import { normalizeError } from "@/utils";
import ArticleForm from "./ArticleForm";

const defaultItem = {
  name: "",
  unit: "U",
  is_free: false,
  unit_price: "",
  description: "",
};

export default {
  name: "ArticleDialog",
  components: { ArticleForm },

  props: {
    open: { type: Boolean, default: false },
  },

  data: () => ({
    article: {},
    errors: {},
    loading: false,
  }),

  created() {
    this.reset();
  },

  computed: {
    prestine() {
      return isEqual(defaultItem, this.article);
    },
    preventSaving() {
      return (
        this.prestine ||
        this.loading ||
        !this.article.name ||
        !this.article.unit
      );
    },
  },

  methods: {
    ...mapActions({
      showToast: "app/showToast",
      createArticle: "articles/createArticle",
    }),

    reset() {
      this.article = Object.assign({}, defaultItem);
      this.errors = {};
    },

    cancel() {
      this.reset();
      this.$emit("cancel");
    },

    async save(item) {
      this.loading = true;

      try {
        const article = await this.createArticle(item);
        this.reset();
        this.$emit("save", article);
      } catch (error) {
        const { message, errors } = normalizeError(error);
        this.errors = errors;
        this.showToast({ text: message, color: "error" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
