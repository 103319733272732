import Invoices from "./Invoices.shell.vue";
import CreateInvoice from "./Invoices.create";
import ShowInvoice from "./Invoices.show";
import EditInvoice from "./Invoices.edit";
import PreviewInvoice from "./Invoices.preview";

export default [
  {
    path: "/factures",
    name: "invoices",
    component: Invoices,
    meta: { title: "Factures" }
  },
  {
    path: "/factures/nouvelle",
    name: "invoices.create",
    component: CreateInvoice,
    meta: { title: "Nouvelle Facture ProForma" }
  },
  {
    path: "/factures/:id",
    name: "invoices.show",
    component: ShowInvoice
  },
  {
    path: "/factures/:id/modifier",
    name: "invoices.edit",
    component: EditInvoice
  },
  {
    path: "/factures/:id/apercu",
    name: "invoices.preview",
    component: PreviewInvoice
  }
];
