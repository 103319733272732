import authService from "./auth.service";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const store = { namespaced: true };

store.state = { currentUser: null };

store.getters = {
  isAuthenticated: state => !!state.currentUser
};

store.mutations = {
  [LOGIN_SUCCESS]: (state, user) => {
    state.currentUser = user;
  },

  [LOGOUT_SUCCESS]: state => {
    state.currentUser = null;
  }
};

store.actions = {
  async login({ commit }, credentials) {
    const user = await authService.login(credentials);
    commit(LOGIN_SUCCESS, user);
  },

  async logout({ commit }) {
    await authService.logout();
    commit(LOGOUT_SUCCESS);
  },

  async check({ commit }) {
    const user = await authService.check();
    user ? commit(LOGIN_SUCCESS, user) : commit(LOGOUT_SUCCESS);
  },

  async saveProfile({ commit }, { id, data }) {
    const user = await authService.saveProfile(id, data);
    commit(LOGIN_SUCCESS, user);
  }
};

export default store;
