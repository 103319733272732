import LoginPage from "./Auth.login";
import ProfilePage from "./Auth.profile";

export default [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: { guest: true, title: "Connexion" }
  },
  {
    path: "/profile",
    name: "users.show",
    component: ProfilePage,
    meta: { title: "Profile" }
  }
];
