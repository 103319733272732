<template>
  <v-card>
    <v-card-title class="b-border pa-2">
      <v-text-field
        v-model="search"
        append-icon="$search"
        label="Chercher..."
        single-line
        hide-details
        clearable
        filled
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-btn
        text
        large
        rounded
        color="primary"
        class="px-4"
        :to="{ name: 'invoices.create' }"
      >
        <v-icon left>$db</v-icon>Nouveau
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="50"
      :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
    >
      <template v-slot:no-data>
        <no-data></no-data>
      </template>

      <template v-slot:[`item.invoice_number`]="{ item }">
        <router-link :to="{ name: 'invoices.show', params: { id: item.id } }">
          {{ item.invoice_number }}
        </router-link>
      </template>

      <template v-slot:[`item.invoice_date`]="{ item }">
        {{ item.invoice_date | date }}
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <v-chip dark pill small :color="item.is_approved ? 'info' : 'orange'">
          {{ item.state }}
        </v-chip>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ item.total | currency }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="accent" class="mr-2" @click.stop="editItem(item)">
          $modify
        </v-icon>
        <v-icon small color="error" @click.stop="deleteItem(item)"> $trash </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NoData from '@/shared/NoData';
import { up } from '@/utils';

export default {
  name: 'InvoicesShell',
  components: { NoData },

  data: () => ({
    loading: true,
    search: '',
    headers: [
      { text: up('N°'), value: 'invoice_number' },
      { text: up('Date'), value: 'invoice_date' },
      { text: up('Client'), value: 'client.name' },
      { text: up('Etat'), value: 'state', align: 'center' },
      { text: up('Montant'), value: 'total', align: 'right' },
      {
        text: up('Actions'),
        value: 'actions',
        sortable: false,
        align: 'right',
      },
    ],
  }),

  async created() {
    try {
      await this.getInvoices();
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    ...mapState({ items: (state) => state.invoices.items }),
  },

  methods: {
    ...mapActions({
      getInvoices: 'invoices/getAllInvoices',
      removeInvoice: 'invoices/removeInvoice',
      showAlert: 'app/showAppAlert',
      clearAlert: 'app/clearAppAlert',
      showToast: 'app/showToast',
    }),

    editItem(item) {
      this.$router.push({ name: 'invoices.edit', params: { id: item.id } });
    },

    async deleteItem(item) {
      try {
        await this.clearAlert();
        const answer = await this.$root.$confirm.destroy();
        if (!answer) return;
        await this.removeInvoice(item.id);
        this.showToast({ text: 'Facture supprimée avec succès!' });
      } catch (error) {
        this.showAlert(error);
      }
    },
  },
};
</script>
