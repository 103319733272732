import invoices from './invoices.service';
import { normalizeInvoice, normalizeInvoiceDetail } from './invoices.helpers';

const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
const REMOVE_INVOICE_SUCCESS = 'REMOVE_INVOICE_SUCCESS';

const CREATE_INVOICE_DETAIL_SUCCESS = 'CREATE_INVOICE_DETAIL_SUCCESS';
const UPDATE_INVOICE_DETAIL_SUCCESS = 'UPDATE_INVOICE_DETAIL_SUCCESS';
const REMOVE_INVOICE_DETAIL_SUCCESS = 'REMOVE_INVOICE_DETAIL_SUCCESS';

const store = { namespaced: true };

store.state = { items: [] };

store.mutations = {
  [GET_ALL_INVOICES]: (state, items) => {
    state.items = items;
  },

  [CREATE_INVOICE_SUCCESS]: (state, item) => {
    state.items.push(item);
  },

  [UPDATE_INVOICE_SUCCESS]: (state, item) => {
    const index = state.items.findIndex((i) => i.id === item.id);
    state.items.splice(index, 1, item);
  },

  [REMOVE_INVOICE_SUCCESS]: (state, id) => {
    state.items = state.items.filter((item) => item.id !== id);
  },

  [CREATE_INVOICE_DETAIL_SUCCESS]: (state, item) => {
    const invoice = state.items.find((v) => v.id === item.invoice_id);
    if (invoice && invoice.details) {
      invoice.details.push(item);
    }
  },

  [UPDATE_INVOICE_DETAIL_SUCCESS]: (state, item) => {
    const invoice = state.items.find((v) => v.id === item.invoice_id);
    if (invoice && invoice.details && invoice.details.length) {
      const index = invoice.details.findIndex((i) => i.id === item.id);
      if (index > -1) invoice.details.splice(index, 1, item);
    }
  },

  [REMOVE_INVOICE_DETAIL_SUCCESS]: (state, item) => {
    const invoice = state.items.find((v) => v.id === item.invoice_id);
    if (invoice && invoice.details && invoice.details.length) {
      const index = invoice.details.findIndex((i) => i.id === item.id);
      if (index > -1) invoice.details.splice(index, 1);
    }
  },
};

store.actions = {
  async getAllInvoices({ commit }) {
    const items = await invoices.getAll();
    commit(GET_ALL_INVOICES, items.map(normalizeInvoice));
  },

  async getInvoice(_, id) {
    const invoice = await invoices.getById(id);
    return normalizeInvoice(invoice);
  },

  async createInvoice({ commit }, payload) {
    const item = await invoices.create(payload);
    const invoice = normalizeInvoice(item);
    commit(CREATE_INVOICE_SUCCESS, invoice);
    return invoice;
  },

  async updateInvoice({ commit }, { id, data }) {
    const item = await invoices.update(id, data);
    const invoice = normalizeInvoice(item);
    commit(UPDATE_INVOICE_SUCCESS, invoice);
    return invoice;
  },

  async removeInvoice({ commit }, id) {
    await invoices.remove(id);
    commit(REMOVE_INVOICE_SUCCESS, id);
  },

  async createInvoiceDetail({ commit }, payload) {
    const item = await invoices.createDetail(payload);
    const detail = normalizeInvoiceDetail(item);
    commit(CREATE_INVOICE_DETAIL_SUCCESS, detail);
    return detail;
  },

  async updateInvoiceDetail({ commit }, { id, data }) {
    const item = await invoices.updateDetail(id, data);
    const detail = normalizeInvoiceDetail(item);
    commit(UPDATE_INVOICE_DETAIL_SUCCESS, detail);
    return detail;
  },

  async removeInvoiceDetail({ commit }, item) {
    await invoices.removeDetail(item.id);
    commit(REMOVE_INVOICE_DETAIL_SUCCESS, item);
  },
};

export default store;
