<template>
  <v-row dense>
    <v-col cols="12">
      <v-toolbar dense rounded elevation="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              rounded
              color="primary"
              @click="save(item)"
              :disabled="preventSaving"
              :loading="saving"
            >
              <v-icon left>$save</v-icon>
              enregistrer
            </v-btn>
          </template>
          <span>enregistrer et afficher la liste des clients</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded color="warning" @click="cancel()">
              <v-icon left>$back</v-icon> annuler
            </v-btn>
          </template>
          <span>annuler et afficher la liste des clients</span>
        </v-tooltip>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-text>
          <client-form :item="item" :errors="errors"></client-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import isEqual from "lodash/isEqual";
import { mapActions } from "vuex";
import { ClientForm } from "./components";
import { normalizeError, normalizeEntity, difference } from "@/utils";

export default {
  name: "EditClient",
  components: { ClientForm },

  data: () => ({
    item: {},
    original: null,
    saving: false,
    loading: true,
    canceled: false,
    errors: {},
  }),

  async created() {
    try {
      const client = await this.getClient(this.$route.params.id);
      this.original = normalizeEntity(client);
      this.reset();
    } catch (error) {
      this.showAlert(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    prestine() {
      return isEqual(this.original, this.item);
    },
    preventSaving() {
      return (
        this.prestine ||
        this.saving ||
        !this.item.name ||
        !(!this.item.email || /.+@.+/.test(this.item.email))
      );
    },
  },

  methods: {
    ...mapActions({
      getClient: "clients/getClient",
      updateClient: "clients/updateClient",
      setPageTitle: "app/setPageTitle",
      showAlert: "app/showAppAlert",
      showToast: "app/showToast",
    }),

    reset() {
      this.item = Object.assign({}, this.original);
      this.errors = {};
      this.setPageTitle(`Clients / ${this.item.name}`);
    },

    cancel() {
      this.canceled = true;
      this.$router.push({ name: "clients" });
    },

    async save(item) {
      this.saving = true;

      try {
        const data = difference(item, this.original);
        await this.updateClient({ id: item.id, data });
        this.showToast({ text: "Client enregistré avec succès" });
        this.reset();
        this.$router.push({ name: "clients" });
      } catch (error) {
        const { errors } = normalizeError(error);
        this.errors = errors;
        this.showAlert(error);
      } finally {
        this.saving = false;
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.prestine || this.canceled) return next();
    const answer = await this.$root.$confirm.leave();
    return answer ? next() : next(false);
  },
};
</script>
